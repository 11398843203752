import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';

import Home  from './pages/Home';
import About from './pages/Contact';
import Login from './pages/SignIn';
import Portal from './pages/Portal';
import Account from './pages/Account';
import Admin from './pages/Admin';
import Dev from './pages/Dev';
import Footer from './components/Footer';
import Header from './components/Header';

Amplify.configure(config);

function App() {
  return (
    <body>
    <div className='App'>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Contact" element={<About />}></Route>
          <Route path="/SignIn" element={<Login />}></Route>
          <Route path="/Portal" element={<Portal />}></Route>
          <Route path="/Account" element={<Account />}></Route>
          <Route path="/Admin" element={<Admin />}></Route>
          <Route path="/Dev" element={<Dev />}></Route>
        </Routes> 
        <Footer />   
      </Router>
    </div>

    </body>
  );
}

export default App;