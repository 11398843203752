import { useState } from 'react';
import { createCustomer } from '../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import { useNavigate} from 'react-router-dom';

export default function Contact() {

    const navigate = useNavigate()

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [job_title, setJobTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleContactFormSubmit = async (event) => {
        event.preventDefault()
        const client = generateClient();

        // form detals
        const contactDetails = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            number: number,
            company_name: company_name,
            job_title: job_title,
            subject: subject,
            message: message
        };

        // validate form
        for (const [key, value] of Object.entries(contactDetails)) {
            if(value === '' || null){ 
            
                document.getElementById(key).style.borderColor = "red";
                 
          } else {

            // INSERT to DynamoDB
            await client.graphql({
                query: createCustomer,
                variables: { input: contactDetails }
                });
            
            // confirm rx
            document.getElementById("contactForm").reset();
            alert('Thanks for reaching out! We\'ll contact you shortly...');

            // go home
            navigate('/');
            return;
          }
        };
    };

    // header effect 
    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if(navbar!=null){
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                navbar.classList.add("nav-sticky");
            } else {
                navbar.classList.remove("nav-sticky");
            }
        }
    };
    
    window.addEventListener('scroll', (ev) => {
        ev.preventDefault();
        windowScroll();
    })

    return (
        <body>
            <title>Radius | Contact</title>

            {/* header */}
            <header id="topnav" class="defaultscroll sticky">
                <div class="container">
                    <div>
                    <a class="logo" href="/">
                        <img src="assets/images/logo.png" height="75" alt="" />
                    </a>
                    </div>
                    <div class="buy-button">
                        <a href="/signin" class="btn btn-primary" id="login-btn">Sign In</a>
                    </div>

                    <div id="navigation">

                    <ul class="navigation-menu" id="navmenu-nav">
                        <li class="has-submenu">
                            <a href="/">Home</a>
                        </li>
                    </ul>
                    </div>
                </div>
            </header>

            {/* form */}
            <section class="section top-1" id="contact">
                <div class="container ">
                    <div class="custom-form mt-3 p-4 shadow-lg rounded">
                        <div class="title-heading ">
                            <h1 class="display-5 fw-bold mb-3">Contact Us</h1>
                            <p>We'd love to hear more about your business and develop a custom solution around your needs.</p>
                        </div>
                        <br></br>
                        <form id='contactForm' name="contactForm" onSubmit={handleContactFormSubmit}>
                            <p id="error-msg"></p>
                            <div id="simple-msg"></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">First Name <span class="text-danger">*</span></label>
                                        <input id="first_name" onChange={evt => setFirstName(evt.target.value)} type="text" class="form-control"/>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Last Name <span class="text-danger">*</span></label>
                                        <input id="last_name" onChange={evt => setLastName(evt.target.value)} type="text" class="form-control"/>                                    </div> 
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Company Email <span class="text-danger">*</span></label>
                                        <input id="email" onChange={evt => setEmail(evt.target.value)} type="email" class="form-control"/>                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Contact Number <span class="text-danger">*</span></label>
                                        <input id="number" onChange={evt => setNumber(evt.target.value)} type="number" class="form-control"/>                                    </div> 
                                </div>

                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Company Name <span class="text-danger">*</span></label>
                                        <input id="company_name" onChange={evt => setCompanyName(evt.target.value)} type="text" class="form-control"/>                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Job Title <span class="text-danger">*</span></label>
                                        <input id="job_title" onChange={evt => setJobTitle(evt.target.value)} type="text" class="form-control"/>                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-3">
                                        <label class="form-label">Subject <span class="text-danger">*</span></label>
                                        <input id="subject" onChange={evt => setSubject(evt.target.value)}type="text" class="form-control"/>                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-3">
                                        <label class="form-label">Message <span class="text-danger">*</span></label>
                                        <textarea id="message" onChange={evt => setMessage(evt.target.value)}type="text" class="form-control"/>                                    </div>
                                    </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-grid">
                                        <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p class="text-muted  mt-3">We're committed to your privacy. Radius Technologies uses the information 
                                you provide to us to contact you about our relevant content, products, and services. You may unsubscribe
                                from these communications at any time. For more information, check out our Privacy Policy.</p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </body>
    )
};