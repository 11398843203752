import React from "react";
import { useLocation } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';

function Footer() {
    //remove footer when not needed
    const { pathname } = useLocation();
    if (pathname === "/admin") return null;
    if (pathname === "/dev") return null;
    if (pathname === "/account") return null;
    if (pathname === "/portal") return null;

    return(
        <div>
            <footer class="bg-dark ">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7 ">
                            <div class="text-center">
                                <a class="logo logo-footer" href="#">
                                    <img src="/assets/images/footer-logo.png" height="75" alt=""/>
                                </a>
                                <p class="text-muted para-desc mx-auto mt-3">You need it. We build it, fast.</p>
                                <ul class="list-unstyled social-icon social mb-0 mt-4">
                                    <li class="list-inline-item"><a href="https://www.linkedin.com/company/radiustech-llc/" class="rounded"><FeatherIcon icon="linkedin" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            
                <a href="#" class="back-to-top" id="back-to-top" title="Top"> 
                    <i class="mdi mdi-arrow-up icons bg-light text-dark"> </i> 
                </a>
                <br />
                <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <p class="mb-0 footer-text para-desc mx-auto">© 2024 Radius Technologies</p>
                        </div>
                </div>
            </footer>
      </div>
  
    )
}

export default Footer