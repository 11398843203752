import '@aws-amplify/ui-react/styles.css';
import { useState } from 'react';
import { signIn } from 'aws-amplify/auth';
// import { signOut } from 'aws-amplify/auth';
import { useNavigate} from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';



export default function Login() {

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()

    const handleSubmit = async function (event) {
        event.preventDefault();
        let response = await signIn({username: username, password: password});
        console.log("auth response", response);
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const isAdmin = accessToken.payload["cognito:groups"].includes('admin')
        const isDev = accessToken.payload["cognito:groups"].includes('devs')
        const isClient = accessToken.payload["cognito:groups"].includes('client')
        //statement to render page based on role
        if (isAdmin) {
            return navigate('/admin');
        }
        if (isDev) {
            return navigate('/dev');
        }
        if (isClient) {
            return navigate('/portal');
        }
        
    };

    // async function handleSignOut() {
    //     try {
    //       await signOut({ global: true });
    //     } catch (error) {
    //       console.log('error signing out: ', error);
    //     }
    //   }

    return (
        <body>
            <title>Radius | Sign In </title>
            <header id="topnav" class="defaultscroll sticky">
                <div class="container">
                    <div class="buy-button">
                        <a href="/" class="btn btn-primary" id="login-btn">Home</a>
                    </div>
                    {/* <div class="buy-button">
                        <a href="/Login" class="btn btn-primary" id="login-btn" onClick={handleSignOut}>Sign Out</a>
                    </div> */}
                </div>
            </header>
            <section class=" vh-100 d-flex align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-8">
                        <div class="text-center">
                            <a>
                                <img src="assets/images/logo.png" height="180" alt=""/>
                            </a>
                        </div>
                        <div class="login-page bg-white shadow-lg rounded p-4 mt-4 position-relative">
                            <div class="text-center">
                                <h5 class="mb-4 pb-2">Sign In</h5>  
                            </div>
                            <form class="login-form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-3 position-relative">
                                            <label class="form-label small fw-bold">Email <span class="text-danger">*</span></label>
                                            <input type="email" class="form-control" name="email" required="" placeholder="Email" onChange={evt => setUserName(evt.target.value)}/>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-3 position-relative">
                                            <label class="form-label small fw-bold">Password <span class="text-danger">*</span></label>
                                            <input type="password" class="form-control" required="" placeholder="Password" onChange={evt => setPassword(evt.target.value)}/>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div >
                                            <p class="forgot-pass"><a href="/reset-password" class="text-dark small fw-bold">Forgot password ?</a></p>
                                        </div>
                                    </div>

                                    <div class="col-12 mb-0">
                                        <button class="btn btn-primary w-100" onClick={handleSubmit}>Sign in</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    </body>

    )};

