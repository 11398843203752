import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from 'aws-amplify/auth';
import './AppHeader.css'
import Logo from './Logo'
import SearchBar from "./SearchBar";
import Nav from "./Nav";
// import SideBar from "./SideBar";

function AppHeader() {
    const navigate = useNavigate()

    //sign out
    async function handleSignOut() {
        try {
          await signOut({ global: true });
          navigate('/SignIn');
        } catch (error) {
          console.log('error signing out: ', error);
        }
      }

    return(
        <header id="topnav" class="defaultscroll sticky header fixed-top d-flex align-items-center ">
            {/* logo */}
            <Logo />
            {/* search */}
            <SearchBar />
            {/* nav */}
            <Nav />
            
            {/* <div class="buy-button container2">
                <a href="/signIn" class="btn btn-primary" id="login-btn" onClick={handleSignOut}>Sign Out</a>
            </div> */}
        </header>
    )
}

export default AppHeader