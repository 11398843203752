import React from 'react'

function NavNotice() {
  return (
    <li className='nav-item dropdown'>
        <a className='nav-link nav-icon' href='#' data-bs-toggle='dropdown'>
            <i className='bi bi-bell'></i>
            <span className='badge bg-primary badge-number'>4</span>
        </a>

        <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications'>
            <li className='dropdown-header'>
                You have 4 new notifications.
                <a href='#'>
                    <span className='badge rounded-pill  bg-primary p-2 ms-2'>
                        View All
                    </span>
                </a>
            </li>
            <li>
                <hr className='dropdown-divider' />
            </li>

            <li className='notification-item'>
                <i className='bi bi-exclamation-circle text-warning'></i>
                <div>
                    <h6> Lorus Ipsum</h6>
                    <p>message content</p>
                    <p>30 minutes ago</p>
                </div>
            </li>

            <li>
                <hr className='dropdown-divider' />
            </li>

            <li className='notification-item'>
                <i className='bi bi-x-circle text-danger'></i>
                <div>
                    <h6> Lorus Ipsum 2</h6>
                    <p>message content 2</p>
                    <p>1 hour ago</p>
                </div>
            </li>

            <li>
                <hr className='dropdown-divider' />
            </li>

            <li className='notification-item'>
                <i className='bi bi-check-circle text-success'></i>
                <div>
                    <h6> Lorus Ipsum 3</h6>
                    <p>message content 3</p>
                    <p>2 hours ago</p>
                </div>
            </li>

            <li>
                <hr className='dropdown-divider' />
            </li>

            <li className='notification-item'>
                <i className='bi bi-info-circle text-primary'></i>
                <div>
                    <h6> Lorus Ipsum 4</h6>
                    <p>message content 4</p>
                    <p>3 hours ago</p>
                </div>
            </li>

            <li>
                <hr className='dropdown-divider' />
            </li>
            
            <li className='dropdown-footer'> 
                <a href='#'> Show All Notifications</a> 
            </li>
        </ul>
    </li>
  );
}

export default NavNotice;