import '@aws-amplify/ui-react/styles.css';
import { signOut } from 'aws-amplify/auth';
import { useNavigate} from 'react-router-dom';

export default function Portal() {

    const navigate = useNavigate()

    async function handleSignOut() {
        try {
          await signOut({ global: true });
          navigate('/SignIn')
        } catch (error) {
          console.log('error signing out: ', error);
        }
      }

    return (
        <body>
            <title>Radius | Portal </title>
            <header id="topnav" class="defaultscroll sticky">
                <div class="container">
                     <div>
                        <a class="logo" href="/">
                            <img src="assets/images/logo.png" height="75" alt="" />
                        </a>
                    </div>
                    <div class="buy-button">
                        <a href="/SignIn" class="btn btn-primary" id="login-btn" onClick={handleSignOut}>Sign Out</a>
                    </div>
                    <div id="navigation">
                        <ul class="navigation-menu" id="navmenu-nav">
                            <li class="has-submenu">
                                <a href="/account">Account</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            
    </body>

    )};

