const navList = [
  {
    _id: 1,
    name: 'Dashboard',
    icon: 'bi bi-grid',
  },
  {
    _id: 2,
    name: 'Documents',
    icon: 'bi bi-file-earmark',
  },
  {
    _id: 3,
    name: 'Projects',
    icon: 'bi bi-card-list',
  },
  {
    _id: 4,
    name: 'Users',
    icon: 'bi bi-people',
  },
  {
    _id: 5,
    name: 'Settings',
    icon: 'bi bi-gear',
  },
  {
    _id: 6,
    name: 'Help',
    icon: 'bi bi-question-circle',
  }
];

export default navList;
