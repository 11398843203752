import React from "react";
import { useLocation } from "react-router-dom";
import './AppHeader.css'

export default function Header() {
  
    //remove when not needed
    const { pathname } = useLocation();
    if (pathname !== "/") return null;

    //responsive menu
    function toggleMenu() {
        document.getElementById('isToggle').classList.toggle('open');
        var isOpen = document.getElementById('navigation')
        if (isOpen.style.display === "block") {
            isOpen.style.display = "none";
        } else {
            isOpen.style.display = "block";
        }
    };

    // Menu sticky
    function windowScroll() {
    const navbar = document.getElementById("topnav");
    if(navbar!=null){
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("nav-sticky");
        } else {
            navbar.classList.remove("nav-sticky");
        }
    }
    };
  
    window.addEventListener('scroll', (ev) => {
        ev.preventDefault();
        windowScroll();
    })

    return(
        <header id="topnav" class="defaultscroll sticky">
        <div class="container">

            <div>
                <a class="logo" href="/">
                    <img src="assets/images/logo.png" height="75" alt="" />
                </a>
            </div>
            <div class="buy-button">
                <a href="/signin" class="btn btn-primary" id="login-btn">Sign In</a>
            </div>

            <div class="menu-extras">
                <div class="menu-item">

                    <a class="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                        <div class="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>

                </div>
            </div>

            <div id="navigation">

                <ul class="navigation-menu" id="navmenu-nav">
                    <li class="has-submenu">
                        <a href="#home">Home</a>
                    </li>
                    <li class="has-submenu">
                        <a href="#solutions">Solutions</a>
                    </li>
                    <li class="has-submenu">
                        <a href="#ourProcess">Our Process</a>
                    </li>
                    <li class="has-submenu">
                        <a href="#faq">FAQ</a>
                    </li>
                    <li class="has-submenu">
                        <a href="/contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    )
};