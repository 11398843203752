import '@aws-amplify/ui-react/styles.css';
import React from "react";
import AppHeader from '../components/AppHeader';
import SideBar from '../components/SideBar'

export default function admin() {

    return (
        
        <body>
            <AppHeader />
            <SideBar />
            <title>Radius | Admin</title>
            
            <br/>
            <br/>
            <br/>
            <div>
                {/* change src url to whatever app */}
                <iframe title='admin' id='frame' src="https://radiustech.retool.com/embedded/public/e14670ae-f834-40bc-b78b-cf89440ac5f5" width="100%" height='860'/>
            </div>
        </body>
    
    )};

