import React from 'react'
import './Logo.css'

function Logo() {
    // sidebar
    const handleToggleSidebar = () => {

        document.body.classList.toggle('toggle-sidebar');
    };
  return (
    <div className='d-flex align-items-center justtify-content-between'>
        <i
            className='bi bi-list toggle-sidebar-btn'
            onClick={handleToggleSidebar}
        ></i>
        <a href='/' className='logo d-flex align-items-center'>
            <img src="assets/images/logo.png" height="60" alt="" />
        </a>

    </div>
  )
}

export default Logo