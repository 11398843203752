
import FeatherIcon from 'feather-icons-react';

export default function home() {

    return (
        
        <body>
            <title>Radius Technologies</title>
            {/* hero */}
            <section class="bg-home d-flex align-items-center" style={{height: "auto"}} id="home">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                            <div class="title-heading margin-top-100">
                                <h1 class="display-4 fw-bold mb-3">Creative Software <span class="text-primary">Solutions</span></h1>
                                <p class="para-desc mx-auto text-muted">You need it. We build it, fast.</p>
                                <div class="col-lg-7 col-md-10 mx-auto">
                                    <div class="text-center subcribe-form mt-4 pt-2 position-relative">
                                        <form>
                                            <div class="form-group mb-0">
                                                
                                                <a href="#solutions" class="btn btn-pills btn-primary position-relative">Get Started</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="home-dashboard">
                                <img src="assets/images/saas/saas-2.png" alt="" class="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* solutions */}
            <section class="container" id="solutions"></section>
            <section>
                <div class="container mt-100 mt-60">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center">
                                <h4 class="title mb-4">Solutions</h4>
                                <p class="text-muted para-desc mx-auto mb-0">We specialize in developing internal tooling integrated with AI, harnessing advanced algorithms to streamline operations, automate tasks, and uncover valuable insights tailored to your organization's needs.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="feature position-relative">
                                <img src="assets/images/chat_app.png" class="img-fluid d-block mx-auto" alt=""/>
                            </div>
                        </div>

                        <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title">
                                <h5 class="title mb-4">Artificial Intelligence</h5>
                                <p class="text-muted para-desc">We develop AI applications that offer tangible benefits to businesses, such as increased efficiency, innovation, and strategic insights.</p>
                                <ul class="list-unstyled">
                                
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Custom AI agents that can autonomously perform tasks, make decisions, and reduce workload.</li>
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Natural Language Processing applications that understand and analyze your data.</li>
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Supply Chain Optimization Tools that improve efficiency and visibility in logistics operations.</li>
                                </ul>
                                <div>
                                    <a href='/contact' class="btn btn-primary mb-2 me-2">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-1 order-md-2">
                            <div class="feature position-relative">
                                <img src="assets/images/saas/features-2.png" class="img-fluid d-block mx-auto" alt=""/>
                            </div>
                        </div>

                        <div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title">
                                <h5 class="title mb-4">Custom Internal Tooling</h5>
                                <p class="text-muted para-desc">Internal tooling facilitates streamlined processes and enhanced productivity within organizations.</p>
                                <ul class="list-unstyled">
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Rapidly deployed apps, seamlessly integrated into your existing infrastructure or hosted on our secure platform, for immediate productivity gains.</li>
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Seemless integration with several existing platforms, enhancing functionality and maximizing efficiency.</li>
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Centralized data management with a unified platform for organizing and accessing data, improving collaboration and decision-making processes.</li>
                                </ul>
                                <div class="watch-video mt-4">
                                    <a href='/contact' class="btn btn-primary mb-2 me-2">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mt-100 mt-60 mb-60">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="feature position-relative">
                                <img src="assets/images/app/01.png" class="img-fluid d-block mx-auto" alt=""/>
                            </div>
                        </div>

                        <div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title">
                                <h5 class="title mb-4">Training</h5>
                                <p class="text-muted para-desc">Elevate your team's proficiency and maximize the potential of our bespoke tools with personalized training, ensuring seamless integration and optimal utilization within your organization.</p>
                                <ul class="list-unstyled">
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Customized sessions designed to fit your team's unique needs and maximize tool effectiveness.</li>
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Practical exercises and interactive sessions to ensure thorough understanding and skill development.</li>
                                    <li class="mt-2" align="left"><i  class="fea icon-sm text-primary me-2"><FeatherIcon icon="check-circle" /></i>Continuous access to resources and assistance to facilitate smooth integration and long-term success.</li>
                                </ul>
                                <div>
                                    <a href='/contact' class="btn btn-primary mb-2 me-2">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* process */}
            <section id="ourProcess"></section>
            <section class="container mt-100 mt-60">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="title mb-4">Our Process</h4>
                                <p class="para-desc mx-auto text-muted mb-0">From initial idea to final deployment, we're with you every step of the way.</p>
                            </div>
                        </div>
                    </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="feature position-relative text-center">
                            <div class="p-4 rounded position-relative overflow-hidden">
                                <div class="icon text-primary">
                                    <i class="uim uim-comment-alt-dots"></i>
                                    <img src="assets/images/shape-1.png" class="avatar avatar-small icon-shape" alt=""/>
                                </div>
                                <div class="content mt-4">
                                    <h5><a class="title text-dark">Planning</a></h5>
                                    <p class="text-muted">Each of our solutions is custom tailored to your needs. Our team will capture your unique requirements to ensure you recieve the best solution for your use case.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                    <div class="feature position-relative text-center">
                        <div class="p-4 rounded position-relative overflow-hidden">
                            <div class="icon text-primary">
                                <i class="uim uim-github-alt"></i>
                                <img src="assets/images/shape-1.png" class="avatar avatar-small icon-shape" alt=""/>
                            </div>
                            <div class="content mt-4">
                                <h5><a class="title text-dark">Development</a></h5>
                                <p class="text-muted">Once your project is scoped, we begin development right away. Log into the client portal anytime to view progress, ask questions, or request changes.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                    <div class="feature position-relative text-center">
                        <div class="p-4 rounded position-relative overflow-hidden">
                            <div class="icon text-primary">
                                <i class="uim uim-flip-v"></i>
                                <img src="assets/images/shape-1.png" class="avatar avatar-small icon-shape" alt=""/>
                            </div>
                            <div class="content mt-4">
                                <h5><a class="title text-dark">Deployment</a></h5>
                                <p class="text-muted">Our low-code development model means deployment in days or weeks, not months or years. We can host your solution or deploy on-prem.</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </section>
            
            {/* FAQ */}
            <section id="faq"></section>
                <div id='faq' class="container mt-100 mt-60">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="title mb-4">Frequently Asked Questions</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="faq-container">
                                <h6 align="left" class="question"><i data-feather="help-circle" class="fea icon-ex-md text-primary me-2"><FeatherIcon icon="help-circle" /></i> How long will it take to deploy my solution?</h6>
                                <p align="left" class="answer text-muted mt-3 mb-0">Once the project is scoped, we typically deploy a minimum viable product within two weeks or less.  From there, we iterate on additional features for another two weeks based on feedback.</p>
                            </div>
                        </div>
                        
                        <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="faq-container">
                                <h6 align="left" class="question"><i data-feather="help-circle" class="fea icon-ex-md text-primary me-2"><FeatherIcon icon="help-circle" /></i> What kind of support and maintenance do you provide after the deployment of internal tools?</h6>
                                <p align="left" class="answer text-muted mt-3 mb-0">We can continue development in two week agile sprints, or provide dedicated support to your project on an ongoing basis. Contact sales for more information. </p>
                            </div>
                        </div>
                        
                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="faq-container">
                                <h6 align="left" class="question"><i data-feather="help-circle" class="fea icon-ex-md text-primary me-2"><FeatherIcon icon="help-circle" /></i>How do you ensure data security and compliance when integrating AI into internal tools?</h6>
                                <p align="left" class="answer text-muted mt-3 mb-0">Data security and compliance are top priorities for us. We adhere to industry best practices and implement robust security measures to safeguard sensitive data. Additionally, we ensure compliance with relevant regulations, such as GDPR and HIPAA, depending on the nature of the data being processed.</p>
                            </div>
                        </div>
                        
                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="faq-container">
                                <h6 align="left"class="question"><i data-feather="help-circle" class="fea icon-ex-md text-primary me-2"><FeatherIcon icon="help-circle" /></i>Can the internal tools be customized to suit our organization's unique processes and workflows?</h6>
                                <p align="left" class="answer text-muted mt-3 mb-0">Yes, absolutely. Our internal tools are highly customizable and tailored to fit your organization's specific needs and workflows. We work closely with clients to understand their requirements and ensure that the tools align with their business objectives.</p>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title">
                                <h4 class="title mb-4">Still have questions? Get in touch!</h4>
                                <a href="/contact" class="btn btn-primary mt-4 scroll-down"><i class="mdi mdi-phone"></i> Contact us</a>
                            </div>
                        </div>
                    </div>
                </div>
                
               {/*spacing*/}
                <footer />
        </body>
    )
};